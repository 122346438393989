var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.visible)?_c('div',{ref:"sidebarcontainer",staticClass:"fixed inset-0 overflow-hidden z-40 bg-opacityblack",attrs:{"tabindex":"0"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.hide.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return _vm.previous.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.next.apply(null, arguments)}],"mousemove":_vm.move,"mouseup":_vm.resizeEnd,"click":_vm.close}},[_c('div',{staticClass:"flex flex-row bg-gray-50"},[_c('section',{ref:"sidebar",staticClass:"absolute inset-y-0 right-0 shadow-xl flex",staticStyle:{"width":"450px"},attrs:{"aria-labelledby":"slide-over-heading"}},[_c('div',{staticClass:"w-6 cursor-col-resize flex sm:items-center bg-gray-50 hover:bg-gray-100",on:{"mousedown":_vm.resizeStart}},[_c('svg',{staticClass:"h-4 w-4 text-gray-600 pointer-events-none",attrs:{"fill":"currentColor","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M8 5h2v14H8zM14 5h2v14h-2z"}})])]),_c('div',{staticClass:"w-screen"},[_c('div',{staticClass:"h-full flex flex-col py-6 bg-white overflow-y-scroll"},[_c('div',{staticClass:"px-4 sm:px-6"},[_c('div',{staticClass:"flex items-start justify-between"},[_c('h2',{staticClass:"text-lg font-medium text-gray-900",attrs:{"id":"slide-over-heading"}},[_vm._v(" Detail View ")]),_c('div',{staticClass:"ml-3 h-7 flex items-center"},[_c('button',{staticClass:"bg-white shadow rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-2 p-1.5 text-sm mr-1",class:[
                    _vm.arrowUpActive
                      ? 'outline-none ring-2 ring-offset-2 ring-indigo-500 border-2 mt-0.5 shadow-none'
                      : '',
                    _vm.isArrowUpEnabled ? '' : 'opacity-40',
                  ],attrs:{"disabled":!_vm.isArrowUpEnabled},on:{"click":_vm.previous}},[_c('arrow-up-icon',{attrs:{"size":"16"}})],1),_c('button',{staticClass:"bg-white shadow rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-2 p-1.5 text-sm mr-1",class:[
                    _vm.arrowDownActive
                      ? 'outline-none ring-2 ring-offset-2 ring-indigo-500 border-2 mt-0.5 shadow-none'
                      : '',
                    _vm.isArrowDownEnabled ? '' : 'opacity-40',
                  ],attrs:{"disabled":!_vm.isArrowDownEnabled},on:{"click":_vm.next}},[_c('arrow-down-icon',{attrs:{"size":"16"}})],1),_c('button',{staticClass:"bg-white shadow rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-2 p-1 text-sm",on:{"click":_vm.hide}},[_vm._v(" ESC ")])])])]),_c('div',{staticClass:"mt-6 relative flex-1 px-4 sm:px-6"},_vm._l((_vm.selectedInteraction.logs),function(log){return _c('div',{key:log.id,staticClass:"bg-white shadow rounded-lg divide-y divide-gray-200 border mb-4"},[_c('inbox-log-type-detail',{attrs:{"log":log}})],1)}),0)])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }