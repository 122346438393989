
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
// import { FormatUtil } from '@/utils/FormatUtil';
import LoadingSpinner from '@/components/layout/partials/LoadingSpinner.vue';
// import dayjs from 'dayjs';
import { InboxMixin } from '@/components/inbox/InboxMixin';
import {
  AudioObject,
  BreakObject,
  InboxLog,
  Interaction,
  InterpretAsObject,
  SSMLObject,
  TextObject,
} from '@jovotech/inbox-core';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Bot } from 'ignite360-core';
import { AppRoute } from '@/router/routes';

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(timezone);

@Component({
  name: 'user-conversation-list-item',
  computed: {
    AppRoute() {
      return AppRoute;
    },
  },
  components: {},
})
export default class UserConversationListItem extends mixins(InboxMixin) {
  @Prop({ type: Object })
  interaction!: Interaction;

  @Prop({ type: String })
  loadingConversation!: string;

  get bot(): Bot | undefined {
    return this.$store.state.bots.bots.find((bot: Bot) => bot.id === this.$route.params.id);
  }

  lastConversationItemRequestText = '';

  isActive = false;

  async mounted() {
    this.lastConversationItemRequestText = await this.retrieveLastConversationItemRequestText(
      this.interaction,
    );

    this.isActive = await this.isUserActive();
  }

  get requestLog() {
    return this.getLogByType(this.interaction, 'request');
  }

  get responseLog() {
    return this.getLogByType(this.interaction, 'response');
  }

  isConversationChecked() {
    const checkedConversation =
      this.$store.state.inbox.checkedConversations[
        this.requestLog!.projectId + '-' + this.requestLog!.userId
      ];
    return checkedConversation && checkedConversation < new Date();
  }

  isSelected(interaction: Interaction) {
    const selectedConversations = this.$store.state.inbox.selectedUserConversations;
    if (
      !selectedConversations ||
      selectedConversations.length === 0 ||
      !interaction.logs ||
      interaction.logs.length === 0
    ) {
      return false;
    }
    return (
      selectedConversations[0].projectId === interaction.logs[0].projectId &&
      selectedConversations[0].userId === interaction.logs[0].userId
    );
  }

  getName(conversation: InboxLog) {
    if (this.nameMap[conversation.userId] && this.nameMap[conversation.userId].name) {
      return this.nameMap[conversation.userId].name;
    }
    return this.shortenUserId(conversation);
  }

  lastConversationItemDate(inboxLog: InboxLog, simple = true) {
    return this.formatDate(inboxLog.createdAt, simple);
  }

  async retrieveLastConversationItemRequestText(interaction: Interaction) {
    const responseInboxLog = this.getLogByType(interaction, 'response');
    const errorInboxLog = this.getLogByType(interaction, 'error');
    if (responseInboxLog) {
      const outputTemplate = await this.getPlatformResponseOutputTemplate(responseInboxLog);
      if (outputTemplate && outputTemplate.length > 0) {
        const lastOutput = outputTemplate[outputTemplate.length - 1];

        const str = this.getOutputText(lastOutput);

        const chunks = this.getMessageChunks(str);

        let previewText = '';

        for (let i = 0; i < chunks.length; i++) {
          const chunk = chunks[i];
          if (chunk.type === 'audio') {
            previewText += '*Audio* ';
          }
          if (chunk.type === 'text') {
            previewText += (chunk as TextObject).text + ' ';
          }
          if (chunk.type === 'break') {
            previewText += '*break* ';
          }
        }

        if (previewText.length > 30) {
          return previewText.substring(0, 30) + '...';
        }

        return previewText;
      }
    }

    if (errorInboxLog) {
      return 'error';
    }

    return '...';
  }

  async isUserActive() {
    if (!this.isLiveMode || !this.responseLog) {
      return false;
    }

    const platformResponse = await this.getPlatformResponseOutputTemplate(this.responseLog);

    const notListening = platformResponse?.some((o) => o.listen === false);

    const logCreatedAt = dayjs(this.responseLog.createdAt);
    const nowMinus5Minutes = dayjs().subtract(5, 'minute');

    return !notListening && logCreatedAt.isAfter(nowMinus5Minutes);
  }

  formatDate(date: string | Date, simple = true) {
    const dateDayJs = dayjs(date);
    if (dateDayJs.isToday()) {
      return dateDayJs.format('h:mm a');
    } else if (dateDayJs.isYesterday()) {
      return 'yesterday,' + dateDayJs.format(' h:mm a');
    } else if (dateDayJs.isBefore(dayjs().subtract(7, 'day'))) {
      return simple ? dateDayJs.format('MMM, DD') : dateDayJs.format('ddd, h:mm a');
    } else if (dateDayJs.isAfter(dayjs().subtract(7, 'day'))) {
      return simple ? dateDayJs.format('ddd') : dateDayJs.format('ddd, h:mm a');
    } else {
      return dateDayJs.format('ddd, MMM, DD h:mm a');
    }
  }

  getMessageChunks(message: string) {
    message = message.replace('<speak>', '').replace('</speak>', '');
    const regex = /\s*(?<!\S)([^\s<>]+(?:\s+[^\s<>]+)*)(?!\S)\s*/;
    const chunks = message.split(regex).filter(Boolean);

    const objects: Array<SSMLObject> = [];

    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i];

      if (!chunk) {
        continue;
      }

      if (chunk.startsWith('<audio')) {
        const srcMatch = chunk.match(/src="([^"]*)"/);
        if (srcMatch && srcMatch.length > 1) {
          const src = srcMatch[1];
          let filename = src.substring(src.lastIndexOf('/') + 1);
          if (filename.length > 35) {
            filename = `${filename.substr(0, 15)}...${filename.substr(-5)}`;
          }
          objects.push({
            type: 'audio',
            src,
            filename,
          } as AudioObject);
        }
      } else if (chunk.startsWith('<break')) {
        const timeMatch = chunk.match(/time="([^"]*)"/);
        if (timeMatch && timeMatch.length > 1) {
          const time = timeMatch[1];
          objects.push({
            type: 'break',
            time,
          } as BreakObject);
        }
      } else if (chunk.startsWith('<say-as')) {
        const interpretAsMatch = chunk.match(/interpret-as="([^"]*)"/);
        if (interpretAsMatch && interpretAsMatch.length > 1) {
          const interpretAs = interpretAsMatch[1];
          const text = chunk.replace(/<[^>]*>/g, '');
          objects.push({
            type: 'interpret-as',
            interpretAs,
            text,
          } as InterpretAsObject);
        }
      } else {
        objects.push({ type: 'text', text: chunk } as TextObject);
      }
    }
    return objects;
  }
}
