
import DetailConversationPart from '@/components/inbox/conversation/DetailConversationPart.vue';
import { InboxLog } from '@jovotech/inbox-core';
import { ChevronDownIcon, ChevronUpIcon, CodeIcon, MonitorIcon, UserIcon } from 'vue-feather-icons';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { InboxMixin } from '@/components/inbox/InboxMixin';

@Component({
  name: 'request-part',
  components: {
    DetailConversationPart,
    MonitorIcon,
    CodeIcon,
    UserIcon,
    ChevronDownIcon,
    ChevronUpIcon,
  },
})
export default class RequestPart extends mixins(InboxMixin) {
  @Prop({ required: true, type: Object })
  part!: InboxLog;

  isErrorCollapsed = false;

  printRequest(log: InboxLog) {
    return this.getPlatformRequest(log);
  }
}
