
import { Component, Watch } from 'vue-property-decorator';
import { InboxLog, Interaction } from '@jovotech/inbox-core';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { ArrowDownIcon, ArrowUpIcon, ChevronDownIcon, ChevronUpIcon } from 'vue-feather-icons';
import { mixins } from 'vue-class-component';
import { EVENT_TYPE_ICON_MAP } from '@/constants';
import InboxLogTypeDetail from '@/components/inbox/conversation/InboxLogTypeDetail.vue';
import { InboxMixin } from '@/components/inbox/InboxMixin';

@Component({
  name: 'detail-conversation-part',
  components: {
    InboxLogTypeDetail,
    VueJsonPretty,
    ArrowUpIcon,
    ArrowDownIcon,
    ChevronDownIcon,
    ChevronUpIcon,
  },
})
export default class DetailConversationPart extends mixins(InboxMixin) {
  isArrowUpEnabled = true;
  arrowUpActive = false;

  isArrowDownEnabled = true;
  arrowDownActive = false;

  resizeActive = false;

  get selectedInboxLog(): InboxLog | null {
    return this.$store.state.inbox.selectedInboxLog;
  }
  get visible() {
    return !!this.selectedInteraction;
  }

  activateButtons() {
    const index = this.interactions.findIndex(
      (item: Interaction) => item.requestId === this.selectedInteraction?.requestId,
    );

    if (index + 1 === this.interactions.length) {
      this.isArrowDownEnabled = false;
    } else {
      this.isArrowDownEnabled = true;
    }

    if (index - 1 < 0) {
      this.isArrowUpEnabled = false;
    } else {
      this.isArrowUpEnabled = true;
    }
  }

  @Watch('selectedInteraction', { deep: true })
  private async onSelectedInteractionChange() {
    this.activateButtons();

    this.$nextTick(async () => {
      if (this.$refs['sidebarcontainer']) {
        (this.$refs['sidebarcontainer'] as HTMLElement).focus();
      }
    });
  }

  get selectedConversation(): InboxLog[] {
    return this.$store.state.inbox.selectedUserConversations;
  }

  async hide() {
    await this.$store.dispatch('inbox/selectInteraction', null);
    this.$emit('hide');
  }

  next() {
    this.arrowDownActive = true;
    setTimeout(async () => {
      this.arrowDownActive = false;

      const index = this.interactions.findIndex(
        (item: Interaction) =>
          new Date(item.start).getTime() ===
          new Date(this.selectedInteraction?.start || new Date()).getTime(),
      );

      if (index + 1 < this.interactions.length) {
        await this.$store.dispatch('inbox/selectInteraction', this.interactions[index + 1]);
      }
      this.activateButtons();
    }, 100);
  }
  previous() {
    this.arrowUpActive = true;
    setTimeout(async () => {
      this.arrowUpActive = false;

      const index = this.interactions.findIndex(
        (item: Interaction) => item.requestId === this.selectedInteraction?.requestId,
      );

      if (index - 1 >= 0) {
        await this.$store.dispatch('inbox/selectInteraction', this.interactions[index - 1]);
      }

      this.activateButtons();
    }, 100);
  }
  resizeStart() {
    this.resizeActive = true;
  }
  async resizeEnd() {
    if (this.resizeActive) {
      this.resizeActive = false;
    }
  }
  move(evt: MouseEvent) {
    if (!this.resizeActive) {
      return;
    }
    const screenWidth =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    const sidebarWidth = screenWidth - evt.x;
    const sidebarElement = this.$refs['sidebar'] as HTMLElement;
    sidebarElement.style.width = sidebarWidth + 'px';
  }

  close(event: MouseEvent) {
    if (!(this.$refs.sidebar as HTMLElement).contains(event.target as HTMLElement)) {
      this.hide();
    }
  }

  getIcon(type: string): string {
    return EVENT_TYPE_ICON_MAP[type] || '';
  }
}
