
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import UserConversationListItem from '@/components/inbox/UserConversationListItem.vue';
import { GetLastConversationsDto, Interaction } from '@jovotech/inbox-core';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';
import { InboxMixin } from '@/components/inbox/InboxMixin';
import { mixins } from 'vue-class-component';
import { AppRoute } from '@/router/routes';
import FilterSettings from '@/components/inbox/FilterSettings.vue';

@Component({
  name: 'inbox-conversation-card',
  components: { FilterSettings, LoadingSpinner, UserConversationListItem },
})
export default class InboxConversationCard extends mixins(InboxMixin) {
  searchQuery = '';
  inSearchMode = false;

  isContentHovered = false;
  lastConversationLoading = false;
  loadingConversation = '';

  isSearchLoading = false;

  @Watch('$route')
  async onRouteChange() {
    if (this.$route.name === AppRoute.ProjectAnalyticsInboxConversation) {
      await this.selectConversation();
    }
  }

  updateSearchMode(val: boolean) {
    this.inSearchMode = val;
  }

  updateSearchLoading(val: boolean) {
    this.isSearchLoading = val;
  }

  async loadConversations(dto: GetLastConversationsDto) {
    if (!this.isLiveMode) {
      await this.$store.dispatch('inbox/clearConversations');
      this.isSearchLoading = true;
    }

    await this.$store.dispatch('inbox/fetchConversations', dto);
    this.isSearchLoading = false;
  }

  async handleScroll(event: Event) {
    try {
      console.log('asdas');
    } catch (e: any) {
      console.log(e);
    }

    const target = event.target as HTMLElement;

    if (!target) {
      return;
    }

    if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
      if (!this.lastConversationLoading && !this.inSearchMode) {
        // await this.loadMore();
      }
    }
  }
}
