
import DetailConversationPart from '@/components/inbox/conversation/DetailConversationPart.vue';
import { InboxLog } from '@jovotech/inbox-core';
import { ChevronDownIcon, ChevronUpIcon, CodeIcon, MonitorIcon, UserIcon } from 'vue-feather-icons';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import type { OutputTemplate } from '@jovotech/framework';
import { InboxMixin } from '@/components/inbox/InboxMixin';
import { getPlatformLogo } from '@/utilities';

@Component({
  name: 'response-part',
  components: {
    DetailConversationPart,
    MonitorIcon,
    CodeIcon,
    UserIcon,
    ChevronDownIcon,
    ChevronUpIcon,
  },
})
export default class ResponsePart extends mixins(InboxMixin) {
  @Prop({ required: true, type: Object })
  part!: InboxLog;

  output: OutputTemplate[] = [];

  async mounted() {
    this.output = await this.getPlatformResponseOutputTemplate(this.part);
  }

  get platformImage() {
    return getPlatformLogo(this.part.platform);
  }

  get quickReplies() {
    const quickReplies = [];

    for (const output of this.output) {
      if (output.quickReplies) {
        for (const quickReply of output.quickReplies) {
          if (typeof quickReply === 'object') {
            quickReplies.push(quickReply.text);
          } else {
            quickReplies.push(quickReply);
          }
        }
      }
    }

    return quickReplies;
  }
}
