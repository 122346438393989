
import { Component, Vue, Watch } from 'vue-property-decorator';
import InboxConversationCard from '@/components/inbox/InboxConversationCard.vue';
import { Bot } from 'ignite360-core';
import { Interaction } from '@jovotech/inbox-core';
import MainPanel from '@/components/inbox/MainPanel.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import DetailSidebarRight from '@/components/inbox/DetailSidebarRight.vue';
import { InboxMixin } from '@/components/inbox/InboxMixin';
import { mixins } from 'vue-class-component';

@Component({
  name: 'project-analytics-inbox',
  components: { DetailSidebarRight, MainPanel, InboxConversationCard },
})
export default class ProjectAnalyticsInbox extends mixins(InboxMixin) {
  get bot(): Bot | undefined {
    return this.$store.state.bots.bots.find((bot: Bot) => bot.id === this.$route.params.id);
  }

  get hasSelectedConversation(): boolean {
    return (
      this.$route.name === this.$routes.ProjectAnalyticsInboxConversation ||
      this.$route.name === this.$routes.ProjectAnalyticsInboxConversationSessions
    );
  }

  @Watch('$route', { immediate: true })
  watchRoute() {
    if (this.$route.name === this.$routes.ProjectAnalyticsInbox) {
      this.$store.commit('inbox/setSelectedUserConversations', []);
    }
  }

  async mounted() {
    if (this.bot) {
      try {
        await this.$store.dispatch('inbox/fetchConversations', {
          projectId: this.bot.id,
        });
      } catch (e) {
        console.error(e);
        this.$notify.error({
          title: 'Fetching inbox data failed',
          description: GENERIC_ERROR_DESCRIPTION,
        });
      }
    }
  }
}
