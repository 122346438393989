
import { Component } from 'vue-property-decorator';
import { CheckIcon, Share2Icon, UserIcon, XIcon } from 'vue-feather-icons';
import { mixins } from 'vue-class-component';
import { InboxLog, InboxLogUser } from '@jovotech/inbox-core';
import { InboxMixin } from '@/components/inbox/InboxMixin';
import { InboxApi } from '@/api/InboxApi';
import InboxLogTypeDetail from '@/components/inbox/conversation/InboxLogTypeDetail.vue';
import UserSettingsModal from '@/components/inbox/conversation/UserSettingsModal.vue';

@Component({
  name: 'detail-sidebar-right',
  components: { UserSettingsModal, InboxLogTypeDetail, Share2Icon, CheckIcon, UserIcon, XIcon },
})
export default class DetailSidebarRight extends mixins(InboxMixin) {
  showSettingsModal = false;
  isCopied = false;

  user: Partial<InboxLogUser> = {};
  isContentHovered = false;

  async handleShareConversation() {
    if (!this.conversation) {
      return;
    }

    if (!this.user.id) {
      try {
        await InboxApi.updateInboxLogUser({
          projectId: this.conversation.projectId,
          platformUserId: this.conversation.userId,
        });
        await this.$store.dispatch('DataModule/buildProjectUsersMap', this.project.id);
        // await this.getInboxLogUserData();
      } catch (e: any) {
        console.log(e);
      }
    }
    this.isCopied = true;
  }

  async close() {
    await this.$store.dispatch('inbox/selectInteraction', null);
  }
}
