
import { Component, Prop } from 'vue-property-decorator';
import { InboxLog } from '@jovotech/inbox-core';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { ArrowDownIcon, ArrowUpIcon, ChevronDownIcon, ChevronUpIcon } from 'vue-feather-icons';
import { mixins } from 'vue-class-component';
import { EVENT_TYPE_ICON_MAP } from '@/constants';
import { InboxMixin } from '@/components/inbox/InboxMixin';

@Component({
  name: 'inbox-log-type-detail',
  components: { VueJsonPretty, ArrowUpIcon, ArrowDownIcon, ChevronDownIcon, ChevronUpIcon },
})
export default class InboxLogTypeDetail extends mixins(InboxMixin) {
  @Prop({ required: true, type: Object })
  log!: InboxLog;

  expanded = false;
  isContentHovered = false;

  getIcon(type: string): string {
    return EVENT_TYPE_ICON_MAP[type] || '';
  }

  get json() {
    return this.log?.payload;
  }
}
