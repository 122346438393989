var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.selectedConversation)?_c('main',{staticClass:"flex-1 relative overflow-hidden focus:outline-none xl:order-first bg-gray-100",attrs:{"tabindex":"0"},on:{"mouseenter":function($event){_vm.isContentHovered = true},"mouseleave":function($event){_vm.isContentHovered = false}}},[(_vm.selectedConversation.length > 0)?_c('div',{staticClass:"inset-0 -mt-4 sm:mt-4 px-0 bg-gray-100"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"w-1/6"}),_c('div',{staticClass:"w-4/6 block"},[_c('div',{staticClass:"border-b border-gray-200"},[_c('nav',{staticClass:"-mb-px flex space-x-8 justify-center",attrs:{"aria-label":"Tabs"}},[_c('router-link',{staticClass:"text-gray-700 hover:text-jovo-blue hover:border-jovo-blue whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm",class:[
                _vm.$route.name === _vm.AppRoute.ProjectAnalyticsInboxConversation
                  ? 'border-gray-700'
                  : 'border-transparent',
              ],attrs:{"to":{
                name: _vm.AppRoute.ProjectAnalyticsInboxConversation,
                params: { projectId: _vm.bot.id, userId: _vm.selectedConversation[0].userId },
              },"aria-current":"page"}},[_vm._v(" All interactions ")]),_c('router-link',{staticClass:"text-gray-700 hover:text-jovo-blue hover:border-jovo-blue whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm",class:[
                _vm.$route.name === _vm.AppRoute.ProjectAnalyticsInboxConversationSessions
                  ? 'border-gray-700'
                  : 'border-transparent',
              ],attrs:{"to":{
                name: _vm.AppRoute.ProjectAnalyticsInboxConversationSessions,
                params: { projectId: _vm.bot.id, userId: _vm.selectedConversation[0].userId },
              }}},[_vm._v(" Sessions "),_c('span',{staticClass:"inline-flex items-center justify-center px-1 py-0.5 mr-2 text-xs font-bold leading-none text-gray-500 bg-gray-200 rounded"},[_vm._v(_vm._s(_vm.sessions.length))])])],1)])]),_c('div',{staticClass:"w-1/6 text-right"},[(false)?_c('button',{staticClass:"inline-flex items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md text-jovo-blue bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",attrs:{"type":"button"},on:{"click":function($event){_vm.showSettingsModal = !_vm.showSettingsModal}}},[_c('user-icon',{attrs:{"size":"16"}})],1):_vm._e()])])]):_vm._e(),(!_vm.loading)?_c('div',{ref:"partContainer",staticClass:"ml-auto font-medium space-y-4 overflow-y-scroll px-4 pt-2 sm:pt-10 pb-36 h-full",class:[_vm.isContentHovered ? 'scrollbar' : 'scrollbar-invisible']},_vm._l((_vm.sessions),function(session){return _c('div',{key:session.sessionId},[_c('div',[_c('div',{staticClass:"my-4 mt-12 mx-auto w-4/5 new-session"},[_c('span',{staticClass:"bg-gray-100"},[_vm._v(_vm._s(_vm.newSessionDate(session.sessionStart)))])]),(_vm.$route.name === _vm.AppRoute.ProjectAnalyticsInboxConversationSessions)?_c('div',{staticClass:"text-center",on:{"click":function($event){return _vm.expandSession(session.sessionId)}}},[_c('span',{staticClass:"inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-200 text-gray-800 mx-1"},[_vm._v(" "+_vm._s(session.interactions.length)+" interactions ")]),_c('span',{staticClass:"text-center"})]):_vm._e()]),(
          _vm.$route.name === _vm.AppRoute.ProjectAnalyticsInboxConversation ||
          _vm.expandedSessions[session.sessionId]
        )?_c('div',_vm._l((session.interactions),function(interaction){return _c('interaction-item',{key:interaction.requestId,attrs:{"interaction":interaction}})}),1):_vm._e()])}),0):_vm._e(),(false)?_c('user-settings-modal',{attrs:{"show":_vm.showSettingsModal},on:{"close":function($event){_vm.showSettingsModal = false}}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }