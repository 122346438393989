
import { Component, Prop, Watch } from 'vue-property-decorator';
import { InboxLog, InboxLogUser } from '@jovotech/inbox-core';
import 'vue-json-pretty/lib/styles.css';
import { mixins } from 'vue-class-component';
import { InboxMixin } from '@/components/inbox/InboxMixin';
import { InboxApi } from '@/api/InboxApi';

@Component({
  name: 'user-settings-modal',
  components: {},
})
export default class UserSettingsModal extends mixins(InboxMixin) {
  @Prop({ type: Boolean, default: false }) show!: boolean;

  isNameEdit = false;
  user: Partial<InboxLogUser> = {};

  oldNameValue = '';

  async handleSaveUserName() {
    this.isNameEdit = false;
    await this.handleSaveUser();
  }

  async handleSaveUser() {
    if (!this.conversation) {
      return;
    }
    try {
      await InboxApi.updateInboxLogUser({
        projectId: this.conversation.projectId,
        platformUserId: this.conversation.userId,
        notes: this.user.notes || '',
        name: this.user.name,
      });
      await this.$store.dispatch('inbox/buildProjectUsersMap', this.bot?.id);
      this.cancel();
    } catch (e: any) {
      this.$notify.error(`Could not save user: ${e.message}`);
    }
  }

  editName() {
    this.isNameEdit = true;
    if (this.user?.name) {
      this.oldNameValue = this.user.name;
    }
    this.$nextTick(() => {
      (this.$refs.name as HTMLFormElement).focus();
    });
  }
  cancelEditName() {
    this.isNameEdit = false;
    this.user.name = this.oldNameValue;
  }

  get conversation(): InboxLog | undefined {
    if (this.selectedConversations.length > 0) {
      return this.selectedConversations[0];
    }
    return undefined;
  }

  @Watch('show')
  async onConversationChange() {
    if (this.show && this.conversation) {
      this.user = {
        name: this.shortenUserId(this.conversation),
      };
      await this.getInboxLogUserData();
    }
  }

  close(event: MouseEvent) {
    if (!(this.$refs.modal as HTMLElement).contains(event.target as HTMLElement)) {
      this.$emit('close');
    }
  }

  cancel() {
    this.$emit('close');
  }

  async getInboxLogUserData() {
    try {
      if (!this.conversation) {
        return;
      }
      const result = await InboxApi.getInboxLogUser({
        platformUserId: this.conversation.userId,
        projectId: this.conversation.projectId,
      });

      this.user = {
        ...result.data,
      };
    } catch (e) {
      console.log(e);
    }
  }
}
