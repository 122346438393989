
import { Component, Watch } from 'vue-property-decorator';
import { SelectUserConversationsDto } from '@jovotech/inbox-core';
import DetailConversationPart from '@/components/inbox/conversation/DetailConversationPart.vue';
import { mixins } from 'vue-class-component';
import RequestPart from '@/components/inbox/conversation/RequestPart.vue';
import ResponsePart from '@/components/inbox/conversation/ResponsePart.vue';
import InteractionItem from '@/components/inbox/conversation/InteractionItem.vue';
import { InboxMixin } from '@/components/inbox/InboxMixin';
import { FormatUtil } from '@/inbox/FormatUtil';
import { AppRoute } from '@/router/routes';
import { UserIcon } from 'vue-feather-icons';
import UserSettingsModal from '@/components/inbox/conversation/UserSettingsModal.vue';

@Component({
  name: 'main-panel',
  computed: {
    AppRoute() {
      return AppRoute;
    },
  },
  components: {
    UserSettingsModal,
    InteractionItem,
    ResponsePart,
    RequestPart,
    DetailConversationPart,
    UserIcon,
  },
})
export default class MainPanel extends mixins(InboxMixin) {
  loading = false;
  isContentHovered = false;

  interval?: number;
  expandedSessions: Record<string, boolean> = {};
  showSettingsModal = false;

  async mounted() {
    await this.selectConversation();

    this.onSelectedConversation();
  }

  newSessionDate(date: string | Date) {
    return FormatUtil.formatDate(date);
  }

  @Watch('selectedConversation')
  onSelectedConversation() {
    this.$nextTick(() => {
      setTimeout(async () => {
        this.scrollToBottom();
        await this.$store.dispatch('inbox/selectInteraction', null);
      }, 100);
    });
  }
  scrollToBottom() {
    const partContainer = this.$refs.partContainer as HTMLDivElement;
    partContainer.scrollTop = partContainer.scrollHeight;
  }

  @Watch('isLiveMode')
  async watchLiveMode() {
    if (this.isLiveMode) {
      this.interval = window.setInterval(async () => {
        const lastLog = this.selectedConversation[this.selectedConversation.length - 1];
        await this.$store.dispatch('inbox/appendUserConversations', {
          userId: lastLog.userId,
          projectId: lastLog.projectId,
          lastId: lastLog.id,
        } as SelectUserConversationsDto);
      }, 1000);
    } else {
      if (this.interval) {
        clearInterval(this.interval);
      }
    }
  }
  expandSession(sessionId: string) {
    const value =
      typeof this.expandedSessions[sessionId] === 'undefined'
        ? false
        : this.expandedSessions[sessionId];

    this.expandedSessions[sessionId] = !value;
    this.$forceUpdate();
  }
}
