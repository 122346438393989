
import { Component, Prop } from 'vue-property-decorator';
import { Interaction } from '@jovotech/inbox-core';
import DetailConversationPart from '@/components/inbox/conversation/DetailConversationPart.vue';
import { mixins } from 'vue-class-component';
import { ChevronDownIcon, ChevronUpIcon } from 'vue-feather-icons';
import RequestPart from '@/components/inbox/conversation/RequestPart.vue';
import ResponsePart from '@/components/inbox/conversation/ResponsePart.vue';
import ErrorPart from '@/components/inbox/conversation/ErrorPart.vue';
import { InboxMixin } from '@/components/inbox/InboxMixin';

@Component({
  name: 'interaction-item',
  components: {
    ErrorPart,
    ResponsePart,
    RequestPart,
    DetailConversationPart,
    ChevronDownIcon,
    ChevronUpIcon,
  },
})
export default class InteractionItem extends mixins(InboxMixin) {
  @Prop({ required: true }) interaction!: Interaction;
  async openDetailView(interaction: Interaction) {
    if (interaction.requestId === this.selectedInteraction?.requestId) {
      await this.$store.dispatch('inbox/selectInteraction', null);
    } else {
      await this.$store.dispatch('inbox/selectInteraction', interaction);
    }
  }

  isInteractionDetailView(interaction: Interaction) {
    return interaction.requestId === this.selectedInteraction?.requestId;
  }
}
